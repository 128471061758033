
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

function PriceFilter() {

  // Our States
  const [value, setValue] = React.useState([0, 650]);
  const [toggle, setToggle] = React.useState(false);

  // Changing State when volume increases/decreases
  const rangeSelector = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setToggle(!toggle)
  }

  return (
    <div className='filter-bar__price-filter'>
      <button aria-haspopup="true" onClick={handleClick} className={`filter-bar__button filter-bar__button--dropdown${toggle ? ' filter-bar__button--active' : ''}`}>Price Range</button>
      {toggle ?
        <>
          <div className='filter-bar__dropdown'>
            <Typography id="range-slider" gutterBottom>
              Select Price Range:
            </Typography><Slider
              value={value}
              onChange={rangeSelector}
              valueLabelDisplay="auto"
              max={650} /><span>£{value[0]} to £{value[1]}</span>
          </div></>
        :
        <></>

      }
    </div>
  );
}

export default PriceFilter;
