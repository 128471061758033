
import React from 'react';

function Header() {
    return (
        <div className="header">
            <div className="header__logo">
            <img src="https://www.flannels.com/images/core/flan-logo-2021-white.svg" alt="Header" />
            </div>
        </div>
    );
}

export default Header;