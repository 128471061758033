
import React from 'react';


function BrandFilter({ filteredProduct }) {

    const [toggle, setToggle] = React.useState(false);

    const categoryList = filteredProduct.map(product => product.brand);

    const handleClick = () => {
        setToggle(!toggle)
    }

    return (
        <div className='filter-bar__category-filter'>
            <button aria-haspopup="true" onClick={handleClick} className={`filter-bar__button filter-bar__button--dropdown${toggle ? ' filter-bar__button--active' : ''}`}>Brands</button>
            {toggle ?
                <>
                    <div className='filter-bar__dropdown'>
                        {categoryList.map((item, index) => (
                            <div className='filter-bar__checkbox' key={index}>
                                <input value={item} type="checkbox" />
                                <span>{item}</span>
                            </div>
                        ))}
                    </div>
                </>
                :
                <></>

            }
        </div>
    );
}

export default BrandFilter;