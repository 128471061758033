import React from 'react';
import Product from './Product';

function SearchResults({filteredProduct}) {
    const filtered = filteredProduct.map(product =>  <Product key={product.id} product={product} />); 
    return (
      <div className="results">
        {filtered}
      </div>
    );
  }
  
  export default SearchResults;
