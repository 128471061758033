// src/App.js

import React from 'react';
import Search from './components/Search';
import productDetails from './data/productList.js';
import Header from './components/Header.js';
import './App.scss'

function App() {
  return (
    <div className="main">
      <Header/>
      <Search details={productDetails} />
    </div>
  );
}

export default App;