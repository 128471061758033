const productDetails = [
    {
      id: 1,
      imgPath: "https://images.flannels.com/images/products/52157718_4plat_a1.jpg",
      name: "Stone Island Jumper",
      brand: "Stone Island",
      description: "Black and Gold Jumper",
      price: "200",
    },
    {
      id: 2,
      imgPath: "https://images.flannels.com/images/products/58461502_4pl.jpg",
      name: "Represent T-shirt",
      brand: "Represent",
      description: "Black Dog Tshirt",
      price: "120",
    },
    {
      id: 3,
      imgPath: "https://images.flannels.com/images/products/52241303_4plat_a1.jpg",
      name: "CP Company Top",
      brand: "CP Company",
      description: "Black CP Company Top",
      price: "80",
    },
    {
      id: 4,
      imgPath: "https://images.flannels.com/images/products/27520903_4plat_a1.jpg",
      name: "Balenciaga Shoes",
      brand: "Balenciaga",
      description: "Speed Trainers",
      price: "650",
    }
  ];
  
  export default productDetails;