import React from 'react';

function Card({ product }) {
  return (
    <div className="product">
      <div className="product__image">
        <img alt={product.name} src={product.imgPath} />
      </div>
      <h2 className='product__title'>{product.name}</h2>
      <p className='product__description'>{product.description}</p>
      <p className="product__price">&#163;{product.price}</p>
    </div>
  );
}

export default Card;