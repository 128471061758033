import React, { useState } from 'react';
import SearchResults from './SearchList';
import PriceFilter from './PriceFilter';
import BrandFilter from './BrandFilter';

function Search({ details }) {

  const [searchField, setSearchField] = useState("");


  const filteredProduct = details.filter(
    product => {
      return (
        product
          .name
          .toLowerCase()
          .includes(searchField.toLowerCase()) ||
        product
          .description
          .toLowerCase()
          .includes(searchField.toLowerCase())
          ||
          product
            .brand
            .toLowerCase()
            .includes(searchField.toLowerCase())
      );
    }
  );

  const handleChange = e => {
    setSearchField(e.target.value);
  };

  return (
    <section className="main">
      <div className="filter-bar">
        <div className="row row--flex">
          <div className="filter-bar__search">
            <input
              type="search"
              placeholder="Search Keyword"
              onChange={handleChange}
            />
          </div>
          <PriceFilter />
          <BrandFilter filteredProduct={details} />
        </div>
      </div>
      <div className="row">
      <SearchResults filteredProduct={filteredProduct} />
      </div>
    </section>
  );
}

export default Search;